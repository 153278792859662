import { useContext, useMemo } from "react";
import PropTypes from "prop-types";

import { CartContext } from "../../context/CartProvider";

import { useCurrentCountryCode } from "../../hooks/usePrices";
import useDiscountsInfo from "../../hooks/useDiscountsInfo";

import getDiscountedItems from "../../helpers/getDiscountedItems";
import { applyCartDiscounts } from "../cartDiscounts";
import { ComponentType } from "../../constants/ComponentType";
import COLORS from "../../constants/Colors";
import { Country } from "../../constants/Country";
import DISCOUNT_TYPES from "../../constants/DiscountTypes";

import * as Styles from "./quantityPercentProgressTracker/styles.module.scss";

function QuantityPercentProgressTrackerGift({
  componentType,
  saleColors,
  discountInfoMode = "current", // check useDiscountsInfo.js
}) {
  const discountInfo = useDiscountsInfo(discountInfoMode);
  const giftInfo = discountInfo.find((s) => s.type === DISCOUNT_TYPES.GIFT_WITH_PURCHASE);

  const { cart } = useContext(CartContext);
  const sideCartInfo = applyCartDiscounts(cart.lines, discountInfo);
  const { lines } = sideCartInfo;

  const countItemsGift = useMemo(() => {
    if (!giftInfo) return;
    const items = getDiscountedItems(giftInfo, lines);

    return items.reduce((red, i) => (red += i.quantity), 0);
  }, [lines, giftInfo]);

  const discounts = discountInfo
    .filter((discount) => discount.type === DISCOUNT_TYPES.QUANTITY_PERCENT)
    .map((discount) => discount.discounts)
    .flat();

  const countryCode = useCurrentCountryCode();
  const backgroundColorReached = saleColors?.tierBgColorLuxeReached ?? COLORS.POPPY;

  const progress = [
    {
      threshold: 1,
      isFilled: countItemsGift >= 1,
      mainContent: "FREE SHIPPING/",
      content: "1 ITEM",
      subContent: "",
    },
  ];

  discounts.forEach(([threshold, discount]) => {
    const progressIndex = progress.findIndex((item) => item.threshold === threshold);
    if (progressIndex !== -1) {
      progress[progressIndex].mainContent = `${discount}% OFF/`;
      progress[progressIndex].subContent = "+ FREE SHIPPING";
      if (countItemsGift >= threshold) {
        progress[progressIndex].isFilled = true;
      }
    } else {
      progress.push({
        threshold,
        isFilled: countItemsGift >= threshold,
        mainContent: `${discount}% OFF/`,
        content: `${threshold} ITEMS`,
        subContent: "",
      });
    }
  });

  const giftThreshold = parseInt(giftInfo?.freegift?.minEligibleQuantity, 10);
  const progressIndex = progress.findIndex((item) => item.threshold === giftThreshold);
  if (progressIndex !== -1) {
    progress[progressIndex].subContent = "+ FREE GIFT";
  } else {
    progress.push({
      threshold: giftThreshold,
      isFilled: countItemsGift >= giftThreshold,
      mainContent: "FREE GIFT/",
      content: `${giftThreshold} ITEMS`,
      subContent: "",
    });
  }

  if (countryCode !== Country.US) return null;

  const paddingByComponentType = {
    [ComponentType.ANNOUNCEMENT_BAR]: "0px",
    [ComponentType.PDP]: "20px",
    default: "15px",
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        gap: "4px",
        paddingBottom: paddingByComponentType[componentType] ?? paddingByComponentType.default,
      }}
    >
      {progress.map((item, index) => (
        <div key={`progress-tiered-gift-${index}`} style={{ flex: 1, width: "auto" }}>
          <div
            className={Styles.tier}
            style={{
              backgroundColor: item.isFilled ? backgroundColorReached : "",
              border: "0.5px solid #000",
              height: "7px",
              borderRadius: "8px",
              marginBottom: "5px",
            }}
          >
            &nbsp;
          </div>
          <div style={{ textAlign: "right", marginRight: "2px", color: "#000" }}>
            <p className={`nanotext ${item.subContent ? Styles.gifting_text_padding : ""}`}>
              <span className="bold">{item.mainContent}</span>&nbsp;
              {item.content}
            </p>
            <p className="nanotext bold">{item.subContent}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default QuantityPercentProgressTrackerGift;

QuantityPercentProgressTrackerGift.propTypes = {
  componentType: PropTypes.oneOf(Object.values(ComponentType)).isRequired,
  saleColors: PropTypes.shape({
    tierTextColorLuxe: PropTypes.string,
    tierTextColorLuxeReached: PropTypes.string,
    tierBgColorLuxe: PropTypes.string,
    tierBgColorLuxeReached: PropTypes.string,
    tierBorderColorLuxe: PropTypes.string,
  }),
  discountInfoMode: PropTypes.string,
};
